.previewCart{
    padding: 12px 20px 0px 20px;
}

.previewCartItem{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 0;
    letter-spacing: 0;
    padding: 20px 0 0 0;
}

.previewCartItem-image{
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 80px;
    text-align: center;
    position: relative;
    top: 6px;
}

.previewCartItem-image img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/
    object-fit: contain;
    object-position: center;
}

.previewCartItem-image:before{
    content: '';
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 107px;
}

.previewCartItem-content{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 80px);
    padding: 0 0 0 20px;
}

.previewCartItem-name{
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-family: var(--product-title-font);
    font-size: var(--product-title-font-size);
    font-weight: var(--product-title-font-weight);
    line-height: 20px;
    letter-spacing: var(--body-letter-spacing);
    color: var(--product-title-color);
    text-transform: var(--product-title-text-transform);
    margin: 0 0 10px 0;
}

.previewCartItem-vendor{
    display: block;
    font-size: var(--font-body-size);
    font-weight: var(--font-weight-normal);
    color: var(--color-text2);
    text-transform: capitalize;
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
}

.previewCartItem-options{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 0 30px 0 0;
    margin: 0 0 7px 0;
}

.previewCartItem-variant{
    display: block;
    font-size: var(--font-body-size);
    font-weight: var(--font-weight-normal);
    color: var(--color-text2);
    text-transform: capitalize;
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
}

.previewCartItem-edit{
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 50%;
    right: -2px;
    transform: translateY(-50%);
    background: none;
    border: none;
}

.previewCartItem-edit .icon{
    width: 18px;
    height: 16px;
    fill: var(--color-text2);
    position: relative;
    top: -2px;
}

.previewCartItem-price{
    margin: 0 0 10px 0;
}

.previewCartItem-price .price{
    display: block;
    font-size: var(--product-price-font-size);
    font-weight: var(--font-weight-semibold);
    letter-spacing: 0;
    line-height: var(--body-line-height);
    color: var(--product-price-color);
}

.previewCartItem-qty{
    display: inline-block;
    vertical-align: middle;
    width: 94px;
    margin-top: 15px;
    position: relative;
}

.previewCartItem-qty .quantity{
    padding: 11px 14px 10px 20px;
}

.previewCartItem-qty input {
    text-align: center;
    padding: 0;
}
.previewCartItem-qty input::-webkit-outer-spin-button,
.previewCartItem-qty input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}

.previewCartItem-qty input[type=number] {
  -moz-appearance: textfield;
}

.previewCartItem-qty .btn-quantity {
    width: 32px;
    height: 45px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: none;
    border: none;
}

.previewCartItem-qty .btn-quantity:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
    left: 10px;
    top: 22px;
    background: #5a5a5a;
}

.previewCartItem-qty .btn-quantity.plus {
    right: 0;
    left: auto;
}

.previewCartItem-qty .btn-quantity.plus:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
    left: 10px;
    top: 22px;
    background: #5a5a5a;
    transform: rotate(90deg);
}

.previewCartItem-remove{
    position: absolute;
    right: 0;
    bottom: 36px;
    background: none;
    border: none;
}

.previewCartItem-remove svg {
    fill: var(--icon);
    width: 18px;
    height: 18px;
}

.previewCartItem + .previewCartItem{
    border-top: 0;
    margin-top: 19px;
}

.previewCartItem{
    border-bottom: var(--cart-item-border-width) var(--cart-item-border-style) var(--cart-item-border);
    padding-bottom: 20px;
    background-color: var(--cart-item-bg);
}

.previewCartItem:last-child{
    border-bottom: 0;
    padding-bottom: 0;
}

.previewCart-emptyBody .empty{
    font-size: var(--font-body-size);
    font-weight: var(--font-weight-normal);
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
    color: var(--color-text2);
    margin: 0 0 22px 0;
}

.previewCartCollection{
    padding: 20px 0px 7px 0px;
    position: relative;
    border-top: 1px solid var(--cart-item-border);
}

.previewCartTitle{
    font-family: var(--font-heading-family);
    font-size: var(--font-heading-size);
    font-weight: var(--font-weight-semibold);
    font-style: var(--font-heading-style);
    line-height: var(--heading-line-height);
    letter-spacing: var(--body-letter-spacing);
    text-transform: unset;
    position: relative;
    margin: 0 0 22px 0;
}

.previewCartCollection .product-item-custom .card-title{
    margin-bottom: 7px;
}

.previewCartCollection .card-button{
    font-size: var(--font-body-size);
    font-weight: var(--font-body-weight);
    line-height: var(--body-line-height);
    letter-spacing: var(--body-letter-spacing);
    color: var(--color-text);
    text-decoration: underline;
    text-underline-offset: 0.2rem;
}

.previewCartCollection .card-swatch{
    text-align: left;
    justify-content: flex-start !important;
    margin-bottom: 14px;
}

.previewCartCollection .product-card-layout-02 .card {
    border: none;
}

.previewCartCarousel .product{
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.previewCartCarousel .slick-arrow{
    padding: 6px;
    width: 35px;
    height: 35px;
    top: -35px;
}

.previewCartCarousel .slick-arrow.slick-prev{
    left: auto !important;
    right: 45px;
    transform: translateY(-50%) rotate(0);
}

.previewCartCarousel .slick-arrow.slick-next{
    right: 0px !important;
}

.previewCartCarousel .slick-arrow.slick-disabled{
    display: block !important;
}

.previewCartCarousel:not(.slick-initialized){
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
    letter-spacing: 0;
}

.previewCartInfo{
    position: relative;
    padding: 5px 30px 90px 30px;
}

.previewCartTotals{
    display: block;
    font-size: 0;
    letter-spacing: 0;
}

.previewCartTotalsPrice {
    /*border-top: 1px solid var(--cart-item-border);*/
    padding-top: 0;
}

.previewCartTotals[data-coupon-discount]  .previewCartTotals-label {
    width: 145px;
}

.cart-discounts .discounts__discount {
    font-size: var(--font-body-size);
    font-weight: var(--font-weight-normal);
    color: var(--color-text2);
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
    padding: 3px 0;
}

.previewCartTotals .previewCartTotals-label,
.previewCartTotals .previewCartTotals-value{
    display: inline-block;
    vertical-align: top;
    font-size: var(--font-body-size);
    line-height: var(--body-line-height);
    letter-spacing: var(--body-letter-spacing);
}

.previewCartTotals .previewCartTotals-label{
    width: 120px;
    text-align: left;
    font-weight: var(--font-weight-semibold);
    color: var(--color-text);
}

.previewCartTotals .previewCartTotals-value{
    width: calc(100% - 120px);
    font-size: calc(var(--font-body-size) + 1px);
    text-align: right;
    font-weight: var(--font-weight-normal);
    color: var(--color-grey);
    font-style: normal;
    letter-spacing: 0;
}

.previewCartTotals.shipTotal .previewCartTotals-value {
    width: 100%;
    text-align: left;
}

.previewCartTotals.total .previewCartTotals-value{
    font-size: calc(var(--font-body-size) + 4px);
}

.previewCartTotals.total .previewCartTotals-value,
.previewCartTotals.subTotal .previewCartTotals-value {
    color: var(--color-text);
    font-weight: var(--font-weight-semibold);
}

.previewCartTotals.shipTotal .previewCartTotals-value {
    font-size: var(--font-body-size);
}

.previewCartTotals + .previewCartTotals{
    margin-top: 9px;
}

.previewCartAction{
    margin: 15px 0 0 0;
}

.previewCartCheckbox input[type="checkbox"] + label{
    display: inline-block;
    vertical-align: top;
    width: auto;
    font-size: var(--font-body-size);
    padding-left: 25px;
    line-height: 20px;
    font-weight: var(--font-weight-normal);
    color: var(--text-cart);
    text-transform: unset;
}

.previewCartCheckbox input[type="checkbox"] ~ button{
    display: inline-block;
    vertical-align: top;
    font-size: var(--font-body-size);
    line-height: 20px;
    color: var(--text-cart);
    margin: 0 0 0 5px;
    padding: 0;
    background: none;
    background-image: linear-gradient(transparent 97%,var(--color-text) 3%);
    background-repeat: repeat-x;
    background-position-y: -2px;
    border: none;
    letter-spacing: var(--body-letter-spacing);
    font-family: var(--font-body-family);
    font-weight: var(--font-weight-normal);
}

.previewCartGroup{
    margin: 12px 0 0 0;
}

.previewCartGroup .button + .button{
    margin-top: 15px;
}

.previewCart .button-continue{
    width: auto;
}

.wrapper-cartCount {
    color: #727272;
    margin-top: 8px;
    /*margin-bottom: 13px;*/
    margin-bottom: 0;
}

.previewCartTool{
    padding: 10px 30px 12px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartTool-item{
    width: 25%;
    text-align: center;
    padding: 16px 10px;
    background-color: #fff;
    border-right: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
    pointer-events: auto;
}

.cartTool-item:hover{
    background-color: #f8f8f8;
}

.cartTool-item:first-child{
    border-left: 1px solid #e2e2e2;
}
.cartTool-item span {
  pointer-events: none;
}
.cartTool-item svg{
    width: 24px;
    height: 24px;
    color: #464646;
}

.cartTool-item.cart-tool-gift svg{
    width: 26px;
}

.cartTool-item.cart-tool-shipping svg{
    width: 32px;
}

body.cart-sidebar-show{
    overflow-y: hidden;
    /*height: 100%;*/
}

body.cart-sidebar-show .background-overlay{
    opacity: 1;
    pointer-events: all;
}

body.cart-sidebar-show .halo-cart-sidebar{
    transform: translate3d(0,0,0);
    opacity: 1;
    visibility: visible;
}

.halo-cart-sidebar .previewCartItem{
    padding-bottom: 25px;
}

.previewCart-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.product-option {
    font-size: var(--font-body-size);
    line-height: 1.5;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 10px;
}

.product-option .definitionList-key {
    float: none;
}

.product-option .definitionList-value {
    flex: 1;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* -------------------- */
.previewCart.active-tool:before {
    content:'';
    position: absolute;
    background-color: #fff;
    opacity: .7;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1
}

.popup-toolDown {
    border-top: 1px solid #e3e3e3;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: var(--bg-white);
    padding: 30px;
    z-index: 9;
    transform: translate3d(0,50%,0);
    opacity: 0;
    visibility: hidden;
    transition: transform var(--duration-default) ease,visibility var(--duration-default) ease,opacity var(--duration-default) ease;
}

.popup-toolDown.show {
    transform: translate3d(0,0,0);
    opacity: 1;
    visibility: visible;
}

.popup-toolDown .cart__note {
    display: block;
    
}

.popup-toolDown label {
    font-family: var(--font-heading-family);
    font-size: calc(var(--font-body-size) + 2px);
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
    color: var(--color-text);
    margin-bottom: 7px;
    display: block;
}

.popup-toolDown label svg {
    width: 20px;
    height: 20px;
    margin-right: 9px;
    position: relative;
    top: -2px;
}

.popup-toolDown button {
    margin-top: 15px;
    margin-bottom: 10px;
}

.popup-toolDown #shipping-calculator h3{
    font-size: 0;
}

.popup-toolDown #shipping-calculator-form-wrapper label {
    font-size: var(--font-body-size);
    font-weight: var(--font-weight-normal);
    color: var(--color-text2);
}

.popup-toolDown #shipping-calculator-form-wrapper p {
    font-size: calc(var(--font-body-size) + 2px);
    font-weight: var(--font-weight-semibold);
    text-transform: capitalize;
    color: var(--color-text);
    margin-bottom: 10px;
    margin-top: 0;
    display: block;
}

.popup-toolDown #shipping-calculator-form-wrapper p svg {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    position: relative;
    top: -3px;
}

.template-cart .hidden-cart {
    display: none;
}

.discount-code-field p {
    margin-top: 0;
    margin-bottom: 14px;
    color: var(--color-text2);
}

#popup-tool-shipping .special-select,
#popup-tool-shipping .styled-input {
  margin-bottom: 10px;
}

#popup-tool-shipping {
    padding-bottom: 12px;
}

#popup-tool-gift{
    text-align: center;
    padding-bottom: 42px;
}

#popup-tool-gift svg{
    width: 26px;
    height: 26px;
    margin-bottom: 12px;
    color: #464646;
}

#shipping-calculator #wrapper-response {
    color: #8b714a;
    background-color: #fbf8e5;
    padding: 14px 20px 12px;
    margin-top: 15px;
}

#popup-tool-discount {
    padding-bottom: 36px;
}

.previewCart .previewCartProduct .product .card {
    display: flex;
    flex-direction: row;
}

.previewCart .previewCartProduct .product .card-product {
    flex: 0 0 33%;
}
.product-card-layout-02 .previewCart .previewCartProduct .card-information__wrapper {
    padding-top: 10px;
}
.previewCart .previewCartProduct .product .card-information .card-action__group {
    display: block !important;
}

.previewCart .previewCartProduct .product .card-information .card-action__group .card-action {
    position: static;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0,0,0);
}

.halo-cart-sidebar .previewCartItem-price .discounts__discount,
.cart-discounts .discounts__discount {
    font-size: calc(var(--font-body-size) - 2px);
    font-weight: var(--font-weight-normal);
    color: var(--color-text2);
    letter-spacing: var(--body-letter-spacing);
    line-height: var(--body-line-height);
    padding-top: 3px;
}

.halo-cart-sidebar .previewCartItem-price .previewCartItem-saving-price {
    display: flex;
    gap: 7px;
}

.halo-cart-sidebar .previewCartItem-price .before-discount-price,
.halo-cart-sidebar .previewCartItem-price .discounted-price {
    font-size: calc(var(--product-price-font-size) - 2px);
    letter-spacing: var(--product-price-letter-spacing);
    font-weight: var(--product-price-font-weight);
    line-height: var(--product-price-line-height);
}

.halo-cart-sidebar .previewCartItem-price .discounted-price {
    font-size: var(--product-price-font-size);
    color: var(--product-sale-price-color);
}

.previewCartTotalsPrice .cart-total-savings {
    display: flex;
}

.cart-discounts + .previewCartItem-change {
    margin-top: 15px;
}

@media (max-width: 1024px){
    .previewCartTool{
        padding: 10px 20px 12px 20px;
    }

    .previewCartInfo{
        padding: 5px 20px 90px 20px;
    }
    
    .previewCart{
        /*height: calc(100vh - 450px);*/
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;
    }

    #popup-tool-gift,
    #popup-tool-shipping,
    #popup-tool-discount,
    #popup-tool-note {
        padding-bottom: 22px;
    }
}

@media (max-width: 551px){
    .previewCart{
        /*height: calc(100vh - 550px);*/
    }
    .halo-cart-sidebar .previewCartItem{
        padding-top: 0;
    }
}

@media (min-width: 551px){
    .previewCartItem{
        padding-top: 0;
    }

    .previewCartItem-image{
        width: 80px;
    }

    .previewCartItem-content{
        width: calc(100% - 80px);
        padding: 0px 20px 0 20px;
        position: relative;
    }

    .previewCartItem-price{
        width: calc(100% - 80px);
        margin-bottom: 0;
    }

    .previewCartItem-qty{
        display: block;
    }

    .previewCartItem-remove{
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        border: none;
    }

    .previewCartItem-remove svg{
        fill: var(--icon);
        width: 18px;
        height: 18px;
    }
}

@media (min-width: 1025px){
    .popup-toolDown.show{
        transform: translateY(-15%) !important;
    }

    .previewCart{
        padding-left: 30px;
        padding-right: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;
        /*height: calc(100vh - 470px);*/
    }

    .previewCartItem-name:hover{
        color: var(--product-title-color-hover);
    }

    .previewCartCarousel .product-item .product-image svg{
        display: block;
    }

    .previewCartCarousel .product-item .product-image:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        background: rgba(0,0,0,.3);
        transition: var(--anchor-transition);
    }

    .previewCartCarousel .product-item .product-image:hover svg{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .previewCartCarousel .product-item .product-image:hover:before{
        opacity: 1;
        visibility: visible;
    }
}
